import { Subject } from 'rxjs';

import { Regions } from '../models/region.model';

export class RegionService {

  regionsChanged = new Subject<Regions[]>();
  regions: Array<Regions> = [];

  getRegions() {
    return this.regions.slice();
  }

  getRegion(index: number) {
    return this.regions[index];
  }

  addRegion(index: number, region: Regions) {
    this.regions.splice(index,0,region);
    this.regionsChanged.next(this.regions.slice());
  }

  addRegions(regions: Regions[]) {
    this.regions.push(...regions);
    this.regionsChanged.next(this.regions.slice());
  }

  deleteRegions() {
    this.regions.splice(0,this.regions.length);
    this.regionsChanged.next(this.regions.slice());
  }

  deleteRegion(index: number) { 
    this.regions.splice(index,1);
    this.regionsChanged.next(this.regions.slice());
  }
}