import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Router } from "@angular/router";
import { Subject } from "rxjs";
import { Inject } from "@angular/core";
import { AuthData } from "./auth-data.model";
import { NewPassData } from "./auth-data.model";
import { DOCUMENT } from "@angular/common";
import { environment } from "src/environments/environment";

@Injectable({ providedIn: "root" })
export class AuthService {
  private isAuthenticated = false;
  private token: string;
  private tokenTimer: any;
  private userId: string;
  public userName: string;
  private isAdmin: boolean;
  private isLoaded;
  public batchAllotted;
  public email;
  public BACKEND_URL;
  private AUTH_BACKEND_URL;

  // Pouch & Couch related details
  private dbUrl: string;
  public userDb: string;
  private userDbKey: string;
  private userDbPwd: string;

  private authStatusListener = new Subject<boolean>();

  constructor(
    private http: HttpClient,
    private router: Router,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.BACKEND_URL = environment.apiUrl
    this.AUTH_BACKEND_URL = this.BACKEND_URL + "/user";
  }

  getToken() {
    return this.token;
  }

  getUserDbDetails() {
    let userDbDetails = {
      dbUrl: this.dbUrl,
      userDb: this.userDb,
      userDbKey: this.userDbKey,
      userDbPwd: this.userDbPwd,
    };
    return userDbDetails;
  }
  getIsAuth() {
    return this.isAuthenticated;
  }

  getUserId() {
    return this.userId;
  }

  getUserName() {
    return this.userName;
  }

  getIsAdmin() {
    return this.isAdmin;
  }

  getAuthStatusListener() {
    return this.authStatusListener.asObservable();
  }

  getBatchAllotted() {
    return this.batchAllotted;
  }

  createUser(email: string, password: string, type: string) {
    const authData: AuthData = { email: email, password: password, type: type };
    this.http.post(this.AUTH_BACKEND_URL + "/signup", authData).subscribe(
      () => {
        if (this.isAdmin) {
          this.router.navigate(["/admin"]);
        } else {
          this.router.navigate(["/wave"]);
        }
      },
      (error) => {
        this.authStatusListener.next(false);
      }
    );
  }

  changePass(email: string, oldPassword: string, newPassword: string) {
    const newPassData: NewPassData = {
      email: email,
      oldpassword: oldPassword,
      newpassword: newPassword,
    };
    this.http.post(this.AUTH_BACKEND_URL + "/change", newPassData).subscribe(
      () => {
        this.router.navigate(["/auth/login"]);
      },
      (error) => {
        this.authStatusListener.next(false);
      }
    );
  }

  login(email: string, password: string) {
    const authData: AuthData = { email: email, password: password };
    this.http
      .post<{
        token: string;
        expiresIn: number;
        userId: string;
        email: string;
        type: string;
        isLoaded: string;
        dbUrl: string;
        userDb: string;
        userDbKey: string;
        userDbPwd: string;
        batchAllotted: string;
      }>(this.AUTH_BACKEND_URL + "/login", authData)
      .subscribe(
        async (response) => {
          const token = response.token;
          this.token = token;
          if (token) {
            const expiresInDuration = response.expiresIn;
            this.setAuthTimer(expiresInDuration);
            this.isAuthenticated = true;
            this.userId = response.userId;
            this.userName = response.email;
            this.isAdmin = response.type == "admin" ? true : false;
            console.log("response.type:", response.type);
            console.log("this.isAdmin:", this.isAdmin);
            this.isLoaded = response.isLoaded;
            this.batchAllotted = response.batchAllotted;

            this.authStatusListener.next(true);
            const now = new Date();
            const expirationDate = new Date(
              now.getTime() + expiresInDuration * 1000
            );
            this.saveAuthData(
              token,
              expirationDate,
              this.userId,
              this.userName,
              this.isAdmin,
              this.batchAllotted
            );
            if (this.isAdmin) {
              this.router.navigate(["/admin"]);
            } else {
              this.router.navigate(["/wave"]);
            }
          }
        },
        (error) => {
          this.authStatusListener.next(false);
          //console.log("error while login ",error);
        }
      );
  }

  autoAuthUser() {
    const authInformation = this.getAuthData();
    if (!authInformation) {
      return;
    }
    const now = new Date();
    const expiresIn = authInformation.expirationDate.getTime() - now.getTime();
    if (expiresIn > 0) {
      this.token = authInformation.token;
      this.isAuthenticated = true;
      this.userId = authInformation.userId;
      this.userName = authInformation.userName;
      this.isAdmin = authInformation.isAdmin;
      this.batchAllotted = authInformation.batchAllotted;
      this.setAuthTimer(expiresIn / 1000);
      this.authStatusListener.next(true);
      if (this.isAdmin) {
        this.router.navigate(["/admin"]);
      } else {
        this.router.navigate(["/wave"]);
      }

    }
  }

  logout() {
    this.token = null;
    this.isAuthenticated = false;
    this.authStatusListener.next(false);
    this.userId = null;
    this.userName = null;
    this.batchAllotted = null;
    clearTimeout(this.tokenTimer);
    this.clearAuthData();
    this.router.navigate(["/auth/login"]);
  }

  private setAuthTimer(duration: number) {
    this.tokenTimer = setTimeout(() => {
      this.logout();
    }, duration * 1000);
  }

  private saveAuthData(
    token: string,
    expirationDate: Date,
    userId: string,
    userName: string,
    isAdmin,
    batchAllotted: string,
  ) {
    localStorage.setItem("token", token);
    localStorage.setItem("expiration", expirationDate.toISOString());
    localStorage.setItem("userId", userId);
    localStorage.setItem("userName", userName);
    localStorage.setItem("isAdmin", isAdmin);
    localStorage.setItem("batchAllotted", batchAllotted);
  }

  private clearAuthData() {
    localStorage.removeItem("token");
    localStorage.removeItem("expiration");
    localStorage.removeItem("userId");
    localStorage.removeItem("userName");
    localStorage.removeItem("isAdmin");
    localStorage.removeItem("batchAllotted");
  }

  public setBatchId(batchId) {
    this.batchAllotted = batchId;
  }

  private getAuthData() {
    const token = localStorage.getItem("token");
    const expirationDate = localStorage.getItem("expiration");
    const userId = localStorage.getItem("userId");
    const userName = localStorage.getItem("userName");
    const batchAllotted = localStorage.getItem("batchAllotted");
    const isAdmin = JSON.parse(localStorage.getItem("isAdmin"));
    if (!token || !expirationDate) {
      return;
    }
    return {
      token: token,
      expirationDate: new Date(expirationDate),
      userId: userId,
      userName: userName,
      isAdmin: isAdmin,
      batchAllotted: batchAllotted
    };
  }
}
