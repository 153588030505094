import { Injectable, EventEmitter } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Subject } from "rxjs";
import { AuthService } from "../auth/auth.service";
import { Wave } from "../models/wave-list.model";
import { Subscription } from "rxjs";
import { MatDialog } from "@angular/material/dialog";
import { Regions } from "../models/region.model";
import * as $ from "jquery";
import { Router } from "@angular/router";

@Injectable({ providedIn: "root" })
export class WaveService {
  private waves: Wave[] = [];
  private wavesUpdated = new Subject<{ waves: Wave[] }>();
  BACKEND_URL;
  WAVE_BACKEND_URL;
  FOLDER_BACKEND_URL;
  folderName = "";
  batchId = "";
  invalidMessage;
  invalidMessageChange = new EventEmitter<any>();

  constructor(
    private http: HttpClient,
    private router: Router,
    private authService: AuthService,
    public dialog: MatDialog
  ) {
    this.WAVE_BACKEND_URL = this.authService.BACKEND_URL + "/waves";
    // console.log("wave backend url" + this.WAVE_BACKEND_URL);
    // this.XML_BACKEND_URL = this.authService.BACKEND_URL + "/api/xml/";
    this.FOLDER_BACKEND_URL = this.authService.BACKEND_URL + "/folder";
    // console.log("folder backend url" + this.FOLDER_BACKEND_URL);
    this.BACKEND_URL = this.authService.BACKEND_URL + "/waves";
  }

  setBatch(batchId) {
    this.batchId = batchId;
    console.log("this.setSessionName inside setSessionName  :  " + this.batchId);
  }

  async getServerWaves(userName) {
    console.log("Getting the lsit of audio files from server for", userName);
    // let batchAllotted = this.authService.getBatchAllotted();
    const queryParams = `?user=${userName}&batchId=${this.batchId}`;
    // console.log("queryParams:", queryParams);
    return this.http
      .get<{ message: string }>(this.WAVE_BACKEND_URL + queryParams)
      .toPromise();
  }

  async getTextFile(fileName) {
    // console.log("Getting the text file from server for", fileName);
    let batchAllotted = this.authService.getBatchAllotted();
    const queryParams = `?fileName=${fileName}&batchId=${batchAllotted}`;
    return this.http
      .get<{ message: string; textData: string }>(
        this.WAVE_BACKEND_URL + queryParams
      )
      .toPromise();
  }

  async updateText(name, text, usableDuration) {
    let batch = this.authService.getBatchAllotted();
    console.log("batch:", batch);
    return this.http
      .post<{ message: string; ok: boolean }>(this.WAVE_BACKEND_URL, {
        textData: text,
        name: name,
        usableDuration: usableDuration,
        batch: batch
      })
      .toPromise();
  }

  async readFileData(fileRead: any) {
    const result = await new Promise((resolve) => {
      let reader = new FileReader();
      if (fileRead.type == "audio/wav" || fileRead.type == "audio/mpeg") {
        reader.onload = (event: any) => {
          // console.log("read file successfully");
          resolve(event.target.result);
        };
        reader.onerror = (event: any) => {
          console.log("error while reading file");
          resolve(null);
        };
        reader.readAsDataURL(fileRead);
      } else {
        reader.onload = (event: any) => {
          console.log("read file successfully");
          resolve(event.target.result);
        };
        reader.onerror = (event: any) => {
          console.log("error while reading file");
          resolve(null);
        };
        reader.readAsText(fileRead);
      }
    });
    return result;
  }
}
