import { Injectable, EventEmitter } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Subject } from "rxjs";
import { AuthService } from "../auth/auth.service";
import { Wave } from "../models/wave-list.model";
import { MatDialog } from "@angular/material/dialog";
import * as $ from "jquery";
import { Router } from "@angular/router";

@Injectable({ providedIn: "root" })
export class MetaService {
  private waves: Wave[] = [];
  private wavesUpdated = new Subject<{ waves: Wave[] }>();
  BACKEND_URL;
  WAVE_BACKEND_URL;
  META_BACKEND_URL;
  invalidMessage;
  invalidMessageChange = new EventEmitter<any>();

  constructor(
    private http: HttpClient,
    private router: Router,
    private authService: AuthService,
    public dialog: MatDialog
  ) {
    this.META_BACKEND_URL = this.authService.BACKEND_URL + "/meta";
    // console.log("Meta backend url" + this.META_BACKEND_URL);
  }

  async getMetaData() {
    // console.log("Getting the lsit of batches from server");
    return this.http
      .get<{ message: string; }>(this.META_BACKEND_URL)
      .toPromise();
  }

  async updateAssignee(assignee, batchId) {
    return this.http
      .put<{ message: string; ok: string }>(this.META_BACKEND_URL, {
        assignee: assignee,
        batchId: batchId,
      })
      .toPromise();
  }

  async readFileData(fileRead: any) {
    const result = await new Promise((resolve) => {
      let reader = new FileReader();
      if (fileRead.type == "audio/wav" || fileRead.type == "audio/mpeg") {
        reader.onload = (event: any) => {
          // console.log("read file successfully");
          resolve(event.target.result);
        };
        reader.onerror = (event: any) => {
          console.log("error while reading file");
          resolve(null);
        };
        reader.readAsDataURL(fileRead);
      } else {
        reader.onload = (event: any) => {
          // console.log("read file successfully");
          resolve(event.target.result);
        };
        reader.onerror = (event: any) => {
          console.log("error while reading file");
          resolve(null);
        };
        reader.readAsText(fileRead);
      }
    });
    return result;
  }
}
