import { Component, OnInit, OnDestroy, EventEmitter } from "@angular/core";
import { HttpClient } from "@angular/common/http";

import * as WaveSurfer from "wavesurfer.js";
import * as TimelinePlugin from "wavesurfer.js/dist/plugin/wavesurfer.timeline.js";
import * as RegionsPlugin from "wavesurfer.js/dist/plugin/wavesurfer.regions.js";
import * as $ from "jquery";
import { Subscription } from "rxjs";
import { Wave } from "../models/wave-list.model";
import { Regions } from "../models/region.model";
import { AuthService } from "../auth/auth.service";
import { WaveService } from "../services/wave-list-service";
import { RegionService } from "../services/region.service";
import { ActivatedRoute, Router } from "@angular/router";
import { isEmptyExpression } from "@angular/compiler";
import { MatSnackBar } from "@angular/material/snack-bar";

@Component({
  selector: "app-wave-viewer",
  templateUrl: "./wave-viewer.component.html",
  styleUrls: ["./wave-viewer.component.css"],
})
export class WaveViewerComponent implements OnInit {
  userName: string;
  subscription: Subscription;
  private waveSub: Subscription;
  waves: any = [];
  curWaveName = "";
  curWaveCount = 0;
  fileChosen = false;
  textFileType = "ooTextFile";
  textFileClass = "TextGrid";
  textGridClass = "IntervalTier";
  textGridName = "Sentence";
  textGridCount: any;
  eventId = null;
  isMenuOpen = false;
  isLast = false;
  status: string = "";
  event: any;
  curTextFileCont: any;
  curScroll: number;
  isLoaded = false;
  regions: Array<Regions>;
  // curWavevalue;
  // Initializing id & start second for creating regions in wavesurfer instance
  soundSeconds = 0;
  timeline;
  regionId: number = 1;
  startSecond: number = 0;
  clickSecond: number = 0;
  regionColor1 = "rgba(0,255,0,0.3)";
  regionColor2 = "rgba(255, 0, 0, 0.2)";
  regionColor = this.regionColor1;
  text_segment_id = "";
  audioFile;
  public wavesurfer: WaveSurfer;
  textElementId;
  isPlaying = false;
  regionsCount: number;
  regionTextArray: string[] = [];
  value = "horizontal";
  fileList = "";
  userIsAuthenticated = false;
  isAdmin;
  private authListenerSubs: Subscription;
  audioCount = 0;
  audioCountChange = new EventEmitter<Number>();
  previousAudios = false;
  nextAudios = false;
  previousAudioChange = new EventEmitter<boolean>();
  nextAudioChange = new EventEmitter<boolean>();
  curWaveNameChange = new EventEmitter<any>();
  curWaveCountChange = new EventEmitter<any>();
  diableSaveBtn = false;

  batches: any[] = [];
  selectedBatch = "";

  playbackSpeed = "1";
  speeds: any[] = [
    { id: 1, name: "X 0.5" },
    { id: 2, name: "X 0.75" },
    { id: 3, name: "X 1" },
    { id: 4, name: "X 1.25" },
    { id: 5, name: "X 1.5" },
    { id: 6, name: "X 1.75" },
    { id: 7, name: "X 2" },
  ];
  selected: number = 3;
  // color = 'white';

  constructor(
    private route: ActivatedRoute,
    private http: HttpClient,
    private rService: RegionService,
    public waveService: WaveService,
    public authService: AuthService,
    private router: Router,
    private _snackBar: MatSnackBar
  ) {


    let keysDown = {};
    window.onkeydown = (e) => {
      keysDown[e.key] = true;
    
      if (keysDown["Control"] && keysDown[" "]) {
        this.playPause()
        e.preventDefault();
      }
    }
    
    window.onkeyup = (e) => {
      keysDown[e.key] = false;
    }
   }

  ngOnInit(): void {
    this.userName = this.authService.getUserName();
    this.waveService.getServerWaves(this.userName).then((response: any) => {
      this.displayWavFiles(response);
    });

    this.userIsAuthenticated = this.authService.getIsAuth();
    console.log("this.userIsAuthenticated", this.userIsAuthenticated);
    this.userName = this.authService.getUserName();
    // console.log("this.userName", this.userName);
    this.isAdmin = this.authService.getIsAdmin();
    // console.log("this.isAdmin" + this.isAdmin);
    this.authListenerSubs = this.authService
      .getAuthStatusListener()
      .subscribe((isAuthenticated) => {
        this.userIsAuthenticated = isAuthenticated;
        this.userName = this.authService.getUserName();
        this.isAdmin = this.authService.getIsAdmin();
        this.selectedBatch = this.authService.getBatchAllotted();
      });

    this.selectedBatch = this.authService.getBatchAllotted();

    // we are creating waveform holder
    this.wavesurfer = WaveSurfer.create({
      height: 170,
      overflow : "unset",
      container: "#waveform",
      scrollParent: false,
      waveColor: "#3fb0ac",
      progressColor: "#547980",
      fillParent: true, //<-- Initially to fill wave full width
      plugins: [
        TimelinePlugin.create({
          container: "#waveform-timeline",
        }),
        RegionsPlugin.create({}),
      ],
      backend: "MediaElement",
    });

    this.wavesurfer.on("error", (status: { name: string }) => {
      this.status = status.name;
      console.log("load error status:", status);
      if (this.status.indexOf("AbortError") == -1) {
        //console.log("this.status: "+this.status);
        this.removeRegions();
        this.isLoaded = false;
        $(".waveEditor").css("display", "none");
      }
    });

    // this.wavesurfer.on("loading", function (progress: any) {
    //   console.log("loading audio in wavesurfer");
    // });

    this.wavesurfer.on("ready", () => {
      // console.log("-------- inside on ready -------");
      this.soundSeconds = this.wavesurfer.getDuration();
      this.soundSeconds = +this.soundSeconds.toFixed(3);
      this.startSecond = +this.startSecond.toFixed(3);
      // console.log(        "this.startSecond",        this.startSecond,        "this.soundSeconds",        this.soundSeconds      );

      //creating text field before creating any regions
      this.createExistRegions();

      this.wavesurfer.params.minPxPerSec = 50;
      var minPxPerSec = this.wavesurfer.params.minPxPerSec;

      var totalPixels = $("#waveform").innerWidth();
      var pxPerSec = totalPixels / this.soundSeconds;
      pxPerSec = +pxPerSec.toFixed(3);
      this.wavesurfer.zoom(pxPerSec);
      $("#textgrid-resize").css("width", totalPixels + "px");

      $("#slider").attr("min", pxPerSec);
      $("#slider").val(pxPerSec);
      $("#waveform").children("wave").css("border-style", "groove").css("overflow" , "unset");
    });

    this.wavesurfer.on("zoom", (pxPerSec: number) => {
      var onZoom = this.soundSeconds * pxPerSec;
      onZoom = +onZoom.toFixed(3);
      $("#textgrid-resize").css("width", onZoom + "px");
    });

    this.wavesurfer.on(
      "scroll",
      (scrollEvent: { target: { scrollLeft: any } }) => {
        var scroll = scrollEvent.target.scrollLeft;
        this.curScroll = scroll;
        var elems = $(".textgrid-segment");
        var len = elems.length;
        for (var i = 0; i < len; i++) {
          $("#" + elems[i].id).css("left", -scroll);
        }
      }
    );

    $(window).resize(() => {
      var totalPixels = $("#waveform").innerWidth();
      var pxPerSec = totalPixels / this.soundSeconds;
      pxPerSec = +pxPerSec.toFixed(3);
      this.wavesurfer.zoom(pxPerSec);
      $("#textgrid-resize").css("width", totalPixels + "px");
      $("#slider").attr("min", pxPerSec);
      $("#slider").val(pxPerSec);
    });

    this.wavesurfer.on("region-dblclick", () => {
      if (this.isMenuOpen == true) {
        this.closeMenu();
      }
      this.createRegion();
      this.clickSecond = this.wavesurfer.getCurrentTime();
      this.clickSecond = +this.clickSecond.toFixed(3);
    });

    this.wavesurfer.on("region-click", () => {
      if (this.isMenuOpen == true) {
        this.closeMenu();
      }
      setTimeout(() => {
        this.getTime();
      }, 500);
    });
  }

  selectPlaybackSpeed(id: number) {
    //getted from event
    // console.log(id);
    //getted from binding
    // console.log("selected speed:", this.selected);
    this.speeds.map((speed) => {
      if (speed.id == id) {
        this.playbackSpeed = speed.name.replace("X ", "");
      }
    });
    this.wavesurfer.setPlaybackRate(this.playbackSpeed);
  }

  getTime() {
    this.clickSecond = this.wavesurfer.getCurrentTime();
    // console.log("this.clickSecond: ", this.clickSecond);
    this.clickSecond = +this.clickSecond.toFixed(3);
  }

  onLogout() {
    this.authService.logout();
  }

  sliderChange(event: { target: { value: any } }) {
    var zoomLevel = event.target.value;
    this.wavesurfer.zoom(zoomLevel);
  }

  displayWavFiles(response: {
    message: any;
    ok: boolean;
    metaData: any;
  }) {
    // console.log("message: ", response.message);
    // console.log("meta:", response.metaData);
    this.openSnackBar(response.message, "");
    if (response.ok == false) {
      console.log("Error while fetching Wave files :", response.message);
    } else {
      // console.log("Successfully fetched Wave files:", response.message);
      // console.log("no. of wave files fetched:", response.waves.length);
      for (let i = 0; i < response.metaData.recordings.length; i++) {
        // console.log("i: ", i, "recordingName:", response.waves[i]);
        let dummyWave = {
          recordingCount: i,
          recordingName: response.metaData.recordings[i].recordingName,
          annotations: [],
          modified: response.metaData.recordings[i].modifiedDate ? true : false
        };
        this.waves.push(dummyWave);
      }

      // console.log("this.waves: ", this.waves);
      this.curWaveName = this.waves[this.audioCount].recordingName;
      this.curWaveCount = this.waves[this.audioCount].recordingCount;
      // console.log(        "response.waves[this.audioCount].recordingName : ",        this.waves[this.audioCount].recordingName      );
      this.loadAudio(this.audioCount);
    }
  }

  annotateThis(event: string) {
    // console.log("annotate this:", event);
    this.status = "";
    this.fileChosen = true;
    const waveLen = this.waves.length;
    let count = 0;
    for (let i = 0; i < waveLen; i++) {
      count = count + 1;
      if (this.waves[i].recordingName == event) {
        this.curWaveName = event;
        this.audioCount = i;
        // console.log("audio count in annotate if condition" + this.audioCount);
        this.loadAudio(i);
      } else if (
        count == this.waves.length &&
        this.waves[i].recordingName != event
      ) {
        //  console.log("i:",i ,"and count:",count);
        // console.log("Please click in the center of the button");
      }
    }
  }

  loadAudio(count: number) {
    // console.log("Load Audio");
    this.removeRegions();
    this.clickSecond = 0;
    const waveLen = this.waves.length;
    // console.log("audio count in loadAudio :: " + this.audioCount);
    this.isLoaded = true;

    if (waveLen - 1 > this.audioCount) {
      this.nextAudios = false;
    } else {
      this.nextAudios = true;
    }

    if (this.audioCount == 0) {
      this.previousAudios = true;
    } else {
      this.previousAudios = false;
    }
    $(".waveEditor").css("display", "block");
    let audioFile = this.waves[this.audioCount].recordingName;
    let audio =
      this.authService.BACKEND_URL +
      "/original/" +
      this.selectedBatch + "/" +
      audioFile;
    // console.log("audio:", audio);
    this.wavesurfer.load(audio);
  }

  async loadText(textFile: string) {
    // console.log("textFile:", textFile);
    await this.waveService.getTextFile(textFile).then((response: any) => {
      // this.displayWavFiles(response);
      // console.log("response.textData:", response.textData);
      // console.log("response.textData.charAt(0):", response.textData.charAt(0));
      // console.log( "isNaN(response.textData.charAt(0)):", isNaN(response.textData.charAt(0)) );
      this.waves[this.audioCount].annotations = [];
      if (response.textData == "") {
        console.log("Unable to fetch textFile");
        let dummyText = {
          regionId: 1,
          startTime: 0,
          endTime: this.soundSeconds,
          regionText: "",
        };
        this.waves[this.audioCount].annotations.push(dummyText);
      } else if (isNaN(response.textData.charAt(0))) {
        // console.log("Inside elseif");
        let dummyText = {
          regionId: 1,
          startTime: 0,
          endTime: this.soundSeconds,
          regionText: response.textData.trim(),
        };
        this.waves[this.audioCount].annotations.push(dummyText);
      } else {
        // let regex = /^\s*$(?:\r\n?|\n)/gm;
        // let dummyLines = response.textData.replace(regex, "");
        let lines = response.textData.split(/\r?\n/);
        // console.log("lines.length: ", lines.length);
        let count = 0;
        lines.map((line: string) => {
          count = count + 1;
          if (line.trim() != "") {
            let res = line.trim();
            let arr = res.split(" ");
            let array = arr.slice(0, 2).concat(arr.slice(2).join(" "));
            // console.log("array : ", array);

            let dummyText = {
              regionId: count,
              startTime: array[0],
              endTime: array[1],
              regionText: array[2],
            };
            // console.log("dummyText:", dummyText);
            this.waves[this.audioCount].annotations.push(dummyText);
          }
        });
      }
    });
  }

  previousAudio() {
    // console.log("previous audio");
    this.audioCount--;
    // console.log("audio count in previous :: " + this.audioCount);
    this.curWaveName = this.waves[this.audioCount].recordingName;
    // console.log("audio name in previous :: " + this.curWaveName);
    this.loadAudio(this.audioCount);
  }

  nextAudio() {
    // console.log("next Audio");
    this.audioCount++;
    // console.log("audio count in next :: " + this.audioCount)
    this.curWaveName = this.waves[this.audioCount].recordingName;
    // console.log("audio name in next :: " + this.curWaveName);
    this.loadAudio(this.audioCount);
  }

  playPause() {
    let currentTime = this.wavesurfer.getCurrentTime();
    currentTime = currentTime.toFixed(3);
    if (this.isPlaying == false) {
      const eleRegions = this.rService.getRegions();
      var eleRegionsCount = eleRegions.length;
      for (let i = 0; i < eleRegionsCount; i++) {
        this.wavesurfer.regions.list[eleRegions[i].regionId].loop = false;
      }
      this.wavesurfer.play();
      $("#playPause").html("<i class='glyphicon glyphicon-pause'></i>");
    } else if (this.isPlaying == true) {
      this.wavesurfer.pause();
      this.clickSecond = this.wavesurfer.getCurrentTime();
      this.clickSecond = +this.clickSecond.toFixed(3);
      $("#playPause").html("<i class='glyphicon glyphicon-play'></i> ");
    }
    this.isPlaying = !this.isPlaying;
  }

  PlayInLoop() {
    this.closeMenu();
    const eleRegions = this.rService.getRegions();
    var eleRegionsCount = eleRegions.length;
    let currentStart: number;
    let currentEnd: number;
    for (let i = 0; i < eleRegionsCount; i++) {
      this.wavesurfer.regions.list[eleRegions[i].regionId].loop = true;
      if (this.eventId == eleRegions[i].regionId) {
        currentStart = eleRegions[i].startTime;
        currentEnd = eleRegions[i].endTime;
      }
    }
    this.wavesurfer.play(currentStart, currentEnd);
    $("#playPause").html("<i class='glyphicon glyphicon-pause'></i> Pause");
    this.isPlaying = !this.isPlaying;
  }

  saveText() {
    this.diableSaveBtn = true;
    // console.log("saving text file");
    let finalRegions = this.rService.getRegions();
    var finalRegionsCount = finalRegions.length;
    let usableDuration = 0;
    for (var i = 0; i < finalRegionsCount; i++) {
      var textRegId = finalRegions[i].regionId;

      let elemText: string = $("#text_segment_" + textRegId)
        .val()
        .toString();
      finalRegions[i].regionText = elemText.trim();
      delete finalRegions[i]["regionPercentage"];
      if (finalRegions[i].regionText.trim() == "" || finalRegions[i].regionText.trim() == "") {
        //skip
      } else {
        usableDuration = usableDuration + (finalRegions[i].endTime - finalRegions[i].startTime);
      }
    }
    // console.log("usableDuration:", usableDuration);
    this.waveService.updateText(this.curWaveName, finalRegions, usableDuration).then((result) => {
      if (result.ok == true) {
        this.waves.map(wave => {
          if (this.curWaveName == wave.recordingName) {
            // console.log("changing color");
            wave.modified = true;
          }
        })
      }
      // console.log("result:", result);
      this.openSnackBar(result.message, "");
      this.diableSaveBtn = false
    })
  }

  createRegion() {
    this.clickSecond = this.wavesurfer.getCurrentTime();
    this.clickSecond = +this.clickSecond.toFixed(3);
    // console.log("inside createRegion with this.clickSecond", this.clickSecond);
    this.regions = this.rService.getRegions();
    this.regionsCount = this.regions.length;
    var newRegion: Regions;

    var getTextElems = $(".textgrid-segment");
    var getTextElemsLen = getTextElems.length;
    const textRegions = this.rService.getRegions();
    var textRegionsCount = textRegions.length;
    if (getTextElemsLen == textRegionsCount) {
      for (var i = 0; i < textRegionsCount; i++) {
        var textRegId = textRegions[i].regionId;
        textRegions[i].regionText = $("#text_segment_" + textRegId)
          .val()
          .toString();
      }
    }

    for (let i = 0; i < this.regionsCount; i++) {
      const curRegion = this.regions[i];
      if (this.clickSecond == curRegion.startTime) {
        break;
      } else if (
        this.clickSecond > curRegion.startTime &&
        this.clickSecond < curRegion.endTime
      ) {
        var regionPercentage = this.calcPercent(
          curRegion.startTime,
          this.clickSecond
        );
        newRegion = new Regions(
          curRegion.regionId,
          curRegion.startTime,
          this.clickSecond,
          regionPercentage,
          curRegion.regionText
        );
        this.rService.addRegion(curRegion.regionId - 1, newRegion);
        curRegion.startTime = this.clickSecond;

        curRegion.regionText = "";
        regionPercentage = this.calcPercent(
          curRegion.startTime,
          curRegion.endTime
        );
        curRegion.regionId = curRegion.regionId + 1;
        curRegion.regionPercentage = regionPercentage;
      } else {
        if (this.clickSecond < curRegion.startTime) {
          curRegion.regionId = curRegion.regionId + 1;
          regionPercentage = this.calcPercent(
            curRegion.startTime,
            curRegion.endTime
          );
          curRegion.regionPercentage = regionPercentage;
        }
      }
    }
    this.renderRegions();
  }

  async createExistRegions() {
    let textFile = this.curWaveName.slice(0, -3) + "txt";
    await this.loadText(textFile);
    const waveLen = this.waves.length;
    for (let i = 0; i < waveLen; i++) {
      // console.log(        "Inside createExistingRegions: this.waves[i].recordingName",        this.waves[i].recordingName,        "this.curWaveName",        this.curWaveName      );
      if (this.waves[i].recordingName == this.curWaveName) {
        // get annotation text from PouchDb
        let textArray = this.waves[i].annotations;
        // console.log("textArray inside createExistingRegions", textArray);
        var curTextRegion: Regions;
        if (textArray.length > 0) {
          // storing the regions data
          for (let i = 0; i < textArray.length; i++) {
            if (textArray.length == 1 && textArray[i].endTime == 0) {
              textArray[i].endTime = this.soundSeconds;
              // console.log( "textArray[i].endTime when endTime is 0", textArray[i].endTime );
            }

            if (textArray.length == 1 && textArray[i].regionText == "") {
              textArray[i].regionText = "";
            }
            let regionPercent = this.calcPercent(
              parseFloat(textArray[i].startTime),
              parseFloat(textArray[i].endTime)
            );
            curTextRegion = new Regions(
              parseFloat(textArray[i].regionId),
              parseFloat(textArray[i].startTime),
              parseFloat(textArray[i].endTime),
              regionPercent,
              textArray[i].regionText
            );
            this.rService.addRegion(textArray[i].regionId - 1, curTextRegion);
          }
          this.renderRegions();
        } else {
          // Adding initial html text element if no corresponding text file
          this.wavesurfer.clearRegions();
          $("#text_tier").empty();
          // $("#new_text_tier").empty();

          this.rService.deleteRegions();
          this.text_segment_id = "text_segment_" + this.regionId;
          var initialPercentage = this.calcPercent(
            this.startSecond,
            this.soundSeconds
          );
          var initialTextElement =
            '<input class="textgrid-segment  border-color0" autocomplete="on" style="width:' +
            initialPercentage +
            '%; font-size: 16px !important" type="text" id="' +
            this.text_segment_id +
            '">';
            
          $("#text_tier").append(initialTextElement);
          // $("#new_text_tier").append(initialTextElement);

          // Adding initial region if no corresponding text file
          curTextRegion = new Regions(
            this.regionId,
            this.startSecond,
            this.soundSeconds,
            initialPercentage,
            ""
          );
          this.rService.addRegion(this.regionId - 1, curTextRegion);
          this.wavesurfer.addRegion({
            id: this.regionId,
            start: this.startSecond,
            end: this.soundSeconds,
            drag: false,
            resize: false,
            color: this.regionColor1,
            loop: true,
          });
        }
        return;
      }
    }
  }

  renderRegions() {
    this.wavesurfer.clearRegions();
    $("#text_tier").empty();
    // $("#text_tier").empty();
    this.regions = this.rService.getRegions();
    this.regionsCount = this.regions.length;
    this.regionTextArray = [];
    // console.log("this.regions", this.regions);
    for (let i = 0; i < this.regionsCount; i++) {
      const editedRegion = this.regions[i];
      // console.log("editedRegion", editedRegion);
      if (this.regionColor == this.regionColor1) {
        this.regionColor = this.regionColor2;
      } else {
        this.regionColor = this.regionColor1;
      }

      this.wavesurfer.addRegion({
        id: editedRegion.regionId,
        start: editedRegion.startTime,
        end: editedRegion.endTime,
        drag: false,
        resize: false,
        color: this.regionColor,
        loop: true,
      });

      var regionTextId = "text_segment_" + editedRegion.regionId;

      var eleText =
        '<input class="textgrid-segment  border-color0" autocomplete="on" style="width: ' +
        editedRegion.regionPercentage +
        '%; font-size: 16px !important" placeholder="Text for the Audio" type="text" value="' +
        editedRegion.regionText +
        '" id="' +
        regionTextId +
        '">';
      this.regionTextArray[editedRegion.regionId - 1] = eleText;
    }

    for (let i = 0; i < this.regionTextArray.length; i++) {
      $("#text_tier").append(this.regionTextArray[i]);
      // $("#new_text_tier").append(this.regionTextArray[i]);
    }
    const eleRegions = this.rService.getRegions();
    var eleRegionsCount = eleRegions.length;

    for (let i = 0; i < eleRegionsCount; i++) {
      var eleTextId = "text_segment_" + eleRegions[i].regionId;
      $("#" + eleTextId).css("width", eleRegions[i].regionPercentage + "%");
      $("#" + eleTextId).css("left", -this.curScroll);
    }
  }

  calcPercent(start: number, end: number) {
    var Percentage = (((end - start) / this.soundSeconds) * 100).toFixed(3);
    return Percentage;
  }

  removeRegions() {
    // Clearing Regions & Removing Text Segments
    this.wavesurfer.pause();
    this.wavesurfer.clearRegions();
    this.rService.deleteRegions();
    $("#text_tier").empty();
    // $("#new_text_tier").empty();

    this.regionId = 1;
    this.startSecond = 0;
    this.text_segment_id = "";
    this.isPlaying = false;
  }

  goToRegionStart(event: { target: { id: any } }) {
    const textId = event.target.id;
    const texNumber = textId.charAt(textId.length - 1);
    const textRegions = this.rService.getRegions();
    var textRegionsCount = textRegions.length;
    for (var i = 0; i < textRegionsCount; i++) {
      if (texNumber == textRegions[i].regionId) {
        var textStart = (textRegions[i].startTime / this.soundSeconds).toFixed(
          3
        );
        const textStartNum = +textStart;
        this.wavesurfer.seekAndCenter(textStartNum);
      }
    }
  }

  openMenu(event: {
    target: { attributes: { [x: string]: { value: any } } };
    preventDefault: () => void;
    clientX: string;
    clientY: string;
  }) {
    this.isMenuOpen = true;
    this.eventId = Number(event.target.attributes["data-id"].value);
    event.preventDefault();

    // enable & disable menu Buttons
    this.regions = this.rService.getRegions();
    this.regionsCount = this.regions.length;
    if (this.eventId - 1 <= 0) {
      $("#mergeBefore").prop("disabled", true);
    } else {
      $("#mergeBefore").prop("disabled", false);
    }
    if (this.eventId + 1 <= this.regionsCount) {
      $("#mergeAfter").prop("disabled", false);
    } else {
      $("#mergeAfter").prop("disabled", true);
    }

    $("#menu").css("display", "block");
    $("#menu").css("left", event.clientX + "px");
    $("#menu").css("top", event.clientY + "px");
  }

  merge(event: { target: { attributes: { id: { nodeValue: any } } } }) {
    this.closeMenu();
    let eventType = event.target.attributes.id.nodeValue;

    //getting the existing text elements value from html
    var getTextElems = $(".textgrid-segment");
    var getTextElemsLen = getTextElems.length;
    const textRegions = this.rService.getRegions();
    var textRegionsCount = textRegions.length;
    if (getTextElemsLen == textRegionsCount) {
      for (var i = 0; i < textRegionsCount; i++) {
        var textRegId = textRegions[i].regionId;
        textRegions[i].regionText = $("#text_segment_" + textRegId)
          .val()
          .toString();
      }
    }

    this.regions = this.rService.getRegions();
    this.regionsCount = this.regions.length;

    for (let i = this.regionsCount - 1; i >= 0; i--) {
      if (this.regions[i].regionId == this.eventId) {
        if (eventType == "mergeAfter") {
          //merging with the region after
          this.regions[i].regionText =
            this.regions[i].regionText + " " + this.regions[i + 1].regionText;
          this.regions[i].endTime = this.regions[i + 1].endTime;
          this.regions[i].regionPercentage = this.calcPercent(
            this.regions[i].startTime,
            this.regions[i].endTime
          );
          this.rService.deleteRegion(i + 1);
        } else if (eventType == "mergeBefore") {
          //merging with the region after
          this.regions[i].regionText =
            this.regions[i - 1].regionText + " " + this.regions[i].regionText;
          this.regions[i].startTime = this.regions[i - 1].startTime;
          this.regions[i].regionPercentage = this.calcPercent(
            this.regions[i].startTime,
            this.regions[i].endTime
          );
          this.regions[i].regionId = this.regions[i].regionId - 1;
          this.rService.deleteRegion(i - 1);
        }
      } else if (this.regions[i].regionId >= this.eventId + 1) {
        this.regions[i].regionId = this.regions[i].regionId - 1;
      }
    }
    this.renderRegions();
  }

  closeMenu() {
    if (this.isMenuOpen == true) {
      this.isMenuOpen = false;
      $("#menu").css("display", "none");
    }
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 2000,
    });
  }




}


