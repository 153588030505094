import { Component, OnInit, OnDestroy, ViewChild, ChangeDetectorRef, NgZone } from "@angular/core";
import { Router, NavigationExtras } from '@angular/router';
import { Subscription } from "rxjs";
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table'
import { AuthService } from "../auth/auth.service";
import { MetaService } from "../services/meta.service";
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from "@angular/material/snack-bar";
import { WaveService } from '../services/wave-list-service';

@Component({
  selector: "app-admin",
  templateUrl: "./admin.component.html",
  styleUrls: ["./admin.component.css"],
})
export class AdminComponent implements OnInit, OnDestroy {
  isLoading = false;

  userIsAuthenticated = false;
  isAdmin;
  private authStatusSub: Subscription;
  displayedColumns = ['batchId', 'actions', 'recordingsCount', 'correctedRecordingsCount', 'totalAudioDuration', 'usableAudioDuration', 'assignee', 'changeAssignee'];
  selectedAssignee
  dataSource: MatTableDataSource<MetaData>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  meta: MetaData[] = [];
  assignees: Assignee[];
  assigneeForm: FormGroup;

  constructor(public waveService: WaveService, private formBuilder: FormBuilder, public authService: AuthService, private metaService: MetaService, private _snackBar: MatSnackBar, private router: Router, private zone: NgZone) {
    this.metaService.getMetaData().then((response: any) => {
      if (response.result.length != 0) {
        this.meta = response.result;
        // console.log("uesrs:", response.users);
        this.assignees = response.users;
        // console.log("assigneesss : ", this.assignees)
        this.dataSource = new MatTableDataSource(this.meta);
        // console.log("data source :", this.dataSource);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      } else {
        console.log("Unable to get meta data");
      }
    });
  }

  ngOnInit(): void {
    this.userIsAuthenticated = this.authService.getIsAuth();
    console.log("this.userIsAuthenticated", this.userIsAuthenticated);
    this.isAdmin = this.authService.getIsAdmin();

    this.authStatusSub = this.authService
      .getAuthStatusListener()
      .subscribe((isAuthenticated) => {
        this.isLoading = false;
        this.userIsAuthenticated = isAuthenticated;
        this.isAdmin = this.authService.getIsAdmin();
      });
    this.assigneeForm = this.formBuilder.group({
      assignees: [null, Validators.required]
    });

  }

  viewBatch(batchId) {
    console.log("batch to viewed:", batchId);
    localStorage.setItem("batchAllotted", batchId);
    this.authService.setBatchId(batchId);
    this.waveService.setBatch(batchId);
    let navigationExtras: NavigationExtras = {
      queryParams: {
        "data": batchId,
      }
    }
    this.zone.run(() => {
      this.router.navigate(["/wave"], navigationExtras);
    });
  }


  onAssigneeChange(ob, batchId) {
    this.selectedAssignee = ob.value;
    console.log('assignee changed... to ', ob, "for :", batchId);
    this.metaService.updateAssignee(this.selectedAssignee, batchId).then(async (result) => {
      console.log("assignee change result:", result);
      this.openSnackBar(result.message, "");
      await this.meta.map((batchData) => {
        if (batchData.batchId == batchId) {
          batchData.assignee = this.selectedAssignee.email;
        }
      });
      this.dataSource = new MatTableDataSource(this.meta);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.assigneeForm.reset();
    });
  }

  onFormSubmit() {
    // this.bookService.saveBook(this.bookForm.value);
    console.log("post value :")
  }

  resetForm() {
    this.assigneeForm.reset();
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.dataSource.filter = filterValue;
  }

  onLogout() {
    this.authService.logout();
  }

  ngOnDestroy() {
    this.authStatusSub.unsubscribe();
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 2000,
    });
  }
}


export interface MetaData {
  batchId: string;
  recordingsCount: number;
  correctedRecordingsCount: number;
  totalAudioDuration: number;
  usableAudioDuration: number;
  assignee: string;
  changeAssignee: any
}


export interface Assignee {
  email: string;
  type: string;
}
